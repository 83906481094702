@use '$styles/mediaqueries';

.ContentSectionContainer {
  display: grid;
  grid-template-columns: 100%;
}

@include mediaqueries.smallOnly {
  .ContentComponentsContainer {
    grid-row-start: var(--tablet-order);
  }
}
@include mediaqueries.largeOnly {
  .ContentComponentsContainer {
    grid-row-start: var(--desktop-order);
  }
}
@include mediaqueries.extraLargeUp {
  .ContentComponentsContainer {
    grid-row-start: var(--widescreen-order);
  }
}
