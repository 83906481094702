@use '$styles/values';

input, textarea {
  font-size: 1rem;
  line-height: values.$space12;
  height: values.$space12;
  padding: values.$space3 values.$space4;
  border: values.$line0-5 solid values.$neutral200;
  border-radius: values.$radius3;
  color: values.$colorNeutral800;

  &::placeholder {
    color: values.$colorNeutral300;
  }

  &:focus {
    outline: none;
    border-color: values.$colorPurple600;
  }
  &:focus-visible {
    &:placeholder-shown {
      outline: none;
      box-shadow: 0 0 0 values.$line0-5 values.$colorPurple400;
    }
  }
  &[pattern] {
    &:valid {
      &:not(:placeholder-shown) {
        border-color: values.$colorGreen300;
      }
    }
    &:invalid {
      &:not(:placeholder-shown) {
        border-color: values.$colorRed300;
      }
    }
  }

  &:read-only {
    color: values.$neutral800;
    border: none;
    background-color: values.$neutral100;
    box-shadow: none;
    &:focus-visible {
      box-shadow: none;
    }
  }
}

textarea {
  min-height: 7.5rem;
}