// @use '$styles/variables/colors';
// @use '$styles/variables/fonts';
@use "$styles/theme" as *; // Now $themeName is available
@use '$styles/tools/extends/typography';
@use '$styles/values';

@if $themeName == motrio {
  /* poppins-regular - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: values.$headlinesFontFamily;
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/poppins/poppins-v22-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* poppins-500 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: values.$headlinesFontFamily;
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/poppins/poppins-v22-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* poppins-600 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: values.$headlinesFontFamily;
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/poppins/poppins-v22-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* poppins-700 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: values.$headlinesFontFamily;
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/poppins/poppins-v22-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
} @else {

  @font-face {
    font-family: values.$headlinesFontFamily;
    src:
      url('/fonts/jokker/Jokker-Heavy.woff2') format('woff2'),
      url('/fonts/jokker/Jokker-Heavy.woff') format('woff');
    font-display: swap;
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: values.$headlinesFontFamily;
    src:
      url('/fonts/jokker/Jokker-Bold.woff2') format('woff2'),
      url('/fonts/jokker/Jokker-Bold.woff') format('woff');
    font-display: swap;
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: values.$headlinesFontFamily;
    src:
      url('/fonts/jokker/Jokker-Semibold.woff2') format('woff2'),
      url('/fonts/jokker/Jokker-Semibold.woff') format('woff');
    font-display: swap;
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: values.$headlinesFontFamily;
    src:
      url('/fonts/jokker/Jokker-SemiboldItalic.woff2') format('woff2'),
      url('/fonts/jokker/Jokker-SemiboldItalic.woff') format('woff');
    font-display: swap;
    font-weight: 600;
    font-style: italic;
  }
  
  @font-face {
    font-family: values.$headlinesFontFamily;
    src:
      url('/fonts/jokker/Jokker-Regular.woff2') format('woff2'),
      url('/fonts/jokker/Jokker-Regular.woff') format('woff');
    font-display: swap;
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: values.$paragraphFontFamily;
    src: url('/fonts/rubik/rubik-v26-latin-300.woff2') format('woff2');
    font-display: swap;
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: values.$paragraphFontFamily;
    src: url('/fonts/rubik/rubik-v26-latin-regular.woff2') format('woff2');
    font-display: swap;
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: values.$paragraphFontFamily;
    src: url('/fonts/rubik/rubik-v26-latin-500.woff2') format('woff2');
    font-display: swap;
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: values.$paragraphFontFamily;
    src: url('/fonts/rubik/rubik-v26-latin-600.woff2') format('woff2');
    font-display: swap;
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: values.$paragraphFontFamily;
    src: url('/fonts/rubik/rubik-v26-latin-700.woff2') format('woff2');
    font-display: swap;
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: values.$campaignFontFamily;
    src: url('/fonts/champ/Champ-SemiBold.otf') format('opentype');
    font-display: swap;
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: values.$campaignFontFamily;
    src: url('/fonts/champ/Champ-Bold.otf') format('opentype');
    font-display: swap;
    font-weight: 700;
    font-style: normal;
  }
}


body {
  @extend %paragraphMTypography;

  // NON-STANDARD RULES - We should not use the following.
  @supports (-webkit-font-smoothing: antialiased) {
    -webkit-font-smoothing: antialiased;
  }
  @supports (-moz-osx-font-smoothing: grayscale) {
    -moz-osx-font-smoothing: grayscale;
  }
}

::selection {
  background: values.$colorYellow200;
}

h1,
.h1 {
  @extend %headlinesH1TypographyStyles;
  margin-top: 0;
  margin-bottom: 0.5em;
}
h2,
.h2 {
  @extend %headlinesH2TypographyStyles;
}
h3,
.h3 {
  @extend %headlinesH3TypographyStyles;
}
h4,
.h4 {
  @extend %headlinesH4TypographyStyles;
}
h5,
.h5 {
  @extend %headlinesH5TypographyStyles;
}
h6,
.h6 {
  @extend %headlinesH6TypographyStyles;
}

p {
  @extend %paragraphMTypography;
}

// I commented this out for now because this overrides the text colour in the primary button
p > a,
p > a:link,
.a,
.a:link {
  color: values.$colorPurple500;
  text-decoration: underline;
  cursor: pointer;
  &:hover,
  &:visited,
  &:active {
    color: values.$colorPurple600;
  }
}
