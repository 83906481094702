@use "src/styles/theme" as *; $themeName: "fixtercore";
@use './config';
@use './elements';
@use './objects/content-components-ordering.scss';

// @note cherry-pick only what's needed from 'antd/dist/reset.css' when migrating components to App Router

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}

// Light weight Ant-Design fixes.
// @deprecated: Remove when Ant-Design is removed from the project.
body :where(.ant-tabs, .ant-row, .ant-col, .ant-input, .ant-modal) {
  font-family: Rubik, sans-serif !important;
  font-variant: tabular-nums !important;
  font-size: inherit !important;
}
body :where(.ant-modal) {
  margin: 0 !important;
}

table {
  border-collapse: collapse;
}

.not-found,
.error {
  margin-top: 20px;
  text-align: center;
}
